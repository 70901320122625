<template>
	<div class="login">
		<div class="login-form">
			<img class="login-bg" src="../../assets/img/logo_bg.png" alt="">
			<div class="form-box">
				<div class="box-title">登陆</div>
				<div class="box-content">
					<el-form ref="ruleFormRef" :model="loginForm" status-icon label-width="100px" class="demo-ruleForm">
						<el-form-item label="手机号：">
							<el-input v-model="loginForm.phone" placeholder="请输入手机号" maxlength="11"
								@input="inputPhone" />
						</el-form-item>
						<el-form-item label="账号：" v-if="loginForm.phone.length >= 11">
							<el-select v-model="loginForm.id" placeholder="请选择登陆账号" style="width: 100%;">
								<el-option v-for="item in accountData" :key="item.id" :label="item.username"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="密码：">
							<el-input v-model="loginForm.password" show-password placeholder="请输入密码" />
						</el-form-item>
					</el-form>
				</div>
				<div class="box-footer">
					<el-button type="warning" round @click="loginFn()" style="width: 100%;">登陆</el-button>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	export default {
		data() {
			return {
				loginForm: {
					phone: '',
					id: '',
					password: '',
				},
				accountData: []
			}
		},
		methods: {
			inputPhone(val) {
				let phoneRegex = /^1[345789]\d{9}$/;
				if (phoneRegex.test(val)) {
					this.$http.post(`erp/v1/users`, {
						phone: val
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.accountData = data
							if (data.length > 0) {
								this.loginForm.id = data[0].id
							}
						} else {
							this.$message.error(msg);
						}
					});
				}

			},
			// 根据手机号查询账号 @visible-change="visibleChange"
			visibleChange(e) {
				if (e == true) {
					this.$http.post(`erp/v1/users`, {
						phone: this.loginForm.phone
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.accountData = data
							if (data.length > 0) {
								this.loginForm.id = data[0].id
							}
						} else {
							this.$message.error(msg);
						}
					});
				}
			},
			// 登陆
			loginFn() {
				if (!this.loginForm.phone) {
					this.$message.error('请输入您的手机号');
					return
				}
				if (!this.loginForm.id) {
					this.$message.error('请选择您的账号');
					return
				}
				if (!this.loginForm.password) {
					this.$message.error('请输入您的密码');
					return
				}
				this.$http.post(`erp/v1/login`, this.loginForm).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						// 存储token
						sessionStorage.setItem('token', data.token)
						// 存储用户信息
						sessionStorage.setItem('member', JSON.stringify(data.member))
						// 存储供应商信息
						sessionStorage.setItem('supplier_info', JSON.stringify(data.supplier_info))
						// 存储路由
						sessionStorage.setItem('activeMenu', 'dataAnalysis');
						// 跳转首页
						this.$router.push("/dataAnalysis");
					} else {
						this.$message.error(msg);
					}
				});
			}
		},
	}
</script>
<style lang='scss' scoped>
	.login {
		width: 100%;
		height: 100%;
		position: relative;

		.login-form {
			height: 400px;
			border-radius: 5px;
			box-shadow: 0px 0px 8px 3px rgba($color: #000000, $alpha: 0.3);
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			justify-content: center;

			.login-bg {
				width: 59%;
				height: 400px;
			}

			.form-box {
				// padding-right: 40px;

				.box-title {
					text-align: center;
					font-size: 30px;
					margin-bottom: 30px;
					margin-top: 30px;
					color: #FC7414;
				}

				.box-content {
					padding-right: 40px;
				}

				.box-footer {
					padding: 0 40px;
					margin-top: 30px;

				}
			}
		}
	}
</style>